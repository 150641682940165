@font-face {
  font-family: "Bodoni MT";
  src: url("fonts/Bodoni MT/BOD_R.TTF");
}

@font-face {
  font-family: "Book Antiqua";
  src: url("fonts/Book Antiqua/BKANT.TTF");
}

:root {
  --main-purple: #1E0032;
  --main-white: #F2F2F2;
}

.App {
  display: flex;
  flex-direction: column;
  width: auto;
}

html, body { 
  overflow-x: hidden; 
} 
body { position: relative }

.bold {
  font-weight: bold;
}

h1, h2 {
  font-family: "Bodoni MT";
  font-weight: normal;
  margin-bottom: 1rem;
}

h1 {
  text-align: center;
  font-size: 30px;
}

p, li {
  font-family: "Book Antiqua";
}

.initial {
  margin-top: 0;
}

section {
  padding: 0rem 1rem 3rem 1rem;
  width: 100%;
}

p, h1, h2 {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

li {
  margin-right: 0.6rem;
}

img {
  margin-bottom: 0.5rem;
}

p, li, a {
  font-size: 1rem;
}

@media only screen and (min-width : 560px) and (max-width : 850px) {
	body {-webkit-text-size-adjust: none;}
}

@media screen and (min-width: 560px){
  section {
    padding: 1rem 1rem 3rem 1rem;
  }
  h1 {
    font-size: 30px;
  }
  p, h1, h2 {
    margin-left: 0px;
  }
  img {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1024px) {
  li, p, a {
    font-size: 1.2rem;
  }
  h1 {
    font-size: 45px;
  }
  h1, h2 {
    margin-bottom: 1.5rem;
  }
}